@import "../pages/PageCommon.module.scss";
.title {
    font-weight: 700 !important;
    font-size: 25px !important;
}
.container {
    color: #000 !important;
    white-space: pre;
}
.action-area {
    justify-content: space-around !important;
    padding: 0px !important;
    .button-text {
        height: 50px;
        text-align: center;
        font-weight: bold;
    }
}