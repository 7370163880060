@import "./PageCommon.module.scss";
.a {
  color: blue;
  border-bottom: 1px solid blue;
}
.message {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  text-align: center;
  font-weight: bold;
}
.message-detail {
  text-align: center;
  padding: 1rem 0 8rem;
  p {
    line-height: 30px;
    white-space: pre-wrap;
  }
}
.image{
  text-align: center;
}
