@import "./PageCommon.module.scss";

.list {
  list-style: inside;
  padding: 10px;
}
.annotation {
  line-height: 20px;
}
.note-message {
  margin-bottom: 5px;
}
.btn-area {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-view-btn-area {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
  .input-view-btn {
    width: 49px;
    height: 37px;
    display: grid;
    border-radius: 5px;
    .btn-icon {
      text-align: center;
      font-size: 28px;
      color: #fff;
    }
  }
}
.file-upload-area {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 15px;
}
.output-area {
  text-align: center;
  color: #f00;
}
.item-area {
  width: 100%;
}