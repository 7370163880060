.App {
  position: relative; /* 相対位置 */
  padding-bottom: 30px; /* フッターの高さを指定 */
}
header {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  z-index: 0;
}
@media screen and (max-width: 1000px) {
  header {
    height: 8.2vw;
  }
}
header::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border-top: 5px solid #b6b7b7;
  height: 16px;
  background: #9d001a;
  z-index: 1;
}
@media screen and (max-width: 1000px) {
  header::before {
    border-top: 0.5vw solid #b6b7b7;
    height: 1.4vw;
  }
}
header::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  height: 16px;
  background-image: url("./assets/images/common/gheader_bg.gif");
  background-size: contain;
  z-index: 2;
  width: 100vw;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1000px) {
  header::after {
    height: 2vw;
  }
}
header a {
  display: inline-block;
}
header .logo {
  margin: 0px 0px 0px 5px;
}
header .logo span {
  overflow: hidden;
  display: block;
  text-indent: 200%;
  white-space: nowrap;
  line-height: 1;
  background-image: url("./assets/images/common/common_img_sprite.png");
  background-position: 0px 0px;
  background-size: contain;
  width: 216px;
  height: 40px;
}
@media screen and (max-width: 1000px) {
  header .logo span {
    width: 21.6vw;
    height: 4vw;
  }
}
.main {
  width: 100%;
}
footer {
  height: 30px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #9d001a;
  font-size: 0.75rem;
  color: #fff;
  position: absolute; /* ←絶対位置 */
  bottom: 0;
}
