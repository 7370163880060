.page-title {
  height: 45px;
  width: 100%;
  background-color: #9d001a;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 12px 20px;
  margin-bottom: 10px;
}
