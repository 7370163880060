.item-wrap {
  margin-bottom: 10px;
}
.item-title {
  font-size: 0.9rem;
  font-weight: bold;
}
.item-description {
  margin-top: 3px;
  margin-bottom: 3px;
}
.item-contents {
  padding: 2px 0 5px 0;
}
