@import './PageCommon.module.scss';

.wrap-btn {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.guideline-paragraph {
  margin-left: 1em;
}
