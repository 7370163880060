@import "./PageCommon.module.scss";

.message {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  text-align: center;
  font-weight: bold;
}
.message-detail {
  padding: 1.5rem 0.5rem 1.5rem;
  background-color: #fff9e5;
  border: solid 2px lightgray;
  p {
    margin: 0 0 1rem 0;
  }
  .hyperlink {
    text-align: center;
    color: blue;
    text-decoration: underline;
    &:hover {
      color: #f00;
    }
  }
}
.notes {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  text-align: center;
  font-size: 75%;
}
