@import './PageCommon.module.scss';

.note-message {
  width: 90%;
  margin: 0 auto;
  p {
    text-align: left;
  }
}
.inner-message {
  margin-top: 10px;
}
.wrap-checkbox {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  .checkbox {
    display: flex;
    align-items: center;
  }
}
.wrap-btn {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
