@import './PageCommon.module.scss';

.a {
  color: blue;
  border-bottom: 1px solid blue;
}
.table {
  counter-reset: rowCount;
  border-collapse: collapse;
  .tbody {
    .tr {
      counter-increment: rowCount;
      .td {
        border: 1px solid;
        padding: 5px;
        &:first-child::before {
          content: counter(rowCount);
        }
      }
    }
  }
}
.ul {
  list-style: disc;
  .li {
    margin-left: 1.5em;
  }
}
.wrap-btn {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
