.area-wrap {
  border: 1px solid #808080;
  border-radius: 2px;
  margin-top: 10px;

  .area-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fffacd;
    border-bottom: 1px solid #808080;
    height: 30px;
    padding: 0 10px;
    font-weight: bold;
    font-size: 0.95rem;

    .mark {
      display: flex;
      align-items: center;
      background-color: #808080;
      color: #fff;
      border-radius: 3px;
      height: 20px;
      font-size: 0.7rem;
      padding: 0 5px;
      font-weight: normal;

      &.require {
        background-color: #f00;
      }
    }
  }

  .area-contents {
    padding: 10px;
    font-size: 0.9rem;
  }
}
