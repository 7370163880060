.file-uploader-wrap {
  input {
    display: none;
  }
}
.file-select {
  width:360;
  border-bottom: 'solid 1px #e9e9e9';
  cursor: pointer;
  padding: '6px 8px';
  color: #1976d2;
  text-align: center;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
}
.file-name-area {
  margin-top: 5px;
  height: 20px;
  margin-bottom: 5px;
}
.label-box {
  position: relative;
  :hover{ background-color: #1976d20a; }
  .label-area {
    position: absolute;
    left: 0px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #1976d2;
    border-top: 1px solid rgb(233, 233, 233);
    font-size: 14px;
  }
}