@import "./PageCommon.module.scss";

.explanation {
  width: 100%;
  word-break: break-all;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.center {
  text-align: center;
}

.confirm-check-area {
  width: 100%;
  padding: 10px 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-btn-area {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-text {
  height: 50px;
  text-align: center;
  font-weight: bold;
  margin-left: 0px !important
}
.file-view-area {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.file-multi-view-area {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width:767.98px) {
    justify-content: normal;
  }
  @media screen and (min-width:768px) {
    justify-content: space-around;
  }
}
.file-multi-over-view-area {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width:767.98px) {
    justify-content: normal;
  }
  @media screen and (min-width:768px) {
    justify-content: flex-start;
  }
}
.file-img-area {
  @media screen and (max-width:767.98px) {
    width: 50%;
    padding-bottom: 12px;
    padding-bottom: 13px;
  }
  @media screen and (min-width:768px) {
    max-width: 107px;
  }
}
.file-name-style {
  font-size: 0.8rem;
  font-weight: normal;
  margin-top: 5px;
  width: 110px;
  word-break: break-all;
}