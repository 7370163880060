.item-frame {
  min-height: 90px;
  background-color: #fff;
  font-size: 1rem;
  padding: 12px 20px;
}
.item-text {
  min-height: 45px;
  font-size: 1rem;
  font-weight: bold;
  padding: 12px 20px;
  margin-bottom: 10px;
  border-bottom: solid 1px gainsboro;
  word-break: break-all;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
