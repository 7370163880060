.wrap {
  padding: 5px 0;
  background-color: #ffff;
  .contents {
    padding: 5px 10px;
    @media screen and (max-width: 999px) {
      width: 100%;
    }
    @media screen and (min-width: 1000px) {
      width: 980px;
    }
    margin: auto;
  }
  .description {
    font-weight: bold;
    font-size: 0.95rem;
  }
  .error {
    color: #f00;
  }
}
