.radio-wrap {
  display: flex;

  .radio-btn {
    margin-right: 5px;
  }
  .radio-label {
    font-size: 0.8rem;
    margin-right: 8px;
  }
}
