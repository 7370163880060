.check-box-wrap {
  display: flex;
  line-height: 0px;
  .check-box-btn {
    margin-right: 5px;
    margin-left: 7px;
  }
  .check-box-label {
    font-size: 14px;
    line-height: 14px;
  }
}
